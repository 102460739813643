import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import type { FormInstance, InputRef } from 'antd';
import { Alert, Button, Form, message, Tabs, theme } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { ProFormCaptcha, ProFormCheckbox, ProFormText, LoginForm } from '@ant-design/pro-form';
import { useIntl, history, FormattedMessage, SelectLang, useModel, useAntdConfigSetter, useAntdConfig } from '@umijs/max';
import Footer from '@/components/Footer';
import {
  login,
  getLoginVerificationCode,
  oidcAuth,
  getLoginOptions,
  updateUserVerify,
  getToken,
  // getOidcLoginOptions,
} from '@/services/api';
import type { ResponseError } from 'umi-request';
import { t, fallbackEnUs, getFingerprint, keyAccessToken, appTitle, capitalizeFirstLetter, getOidcSettings } from '../../../global';
import type { API } from '@/services/typings';
import queryString from 'query-string';

import styles from './index.less';
import Bowser from 'bowser';
import { useEmotionCss } from '@ant-design/use-emotion-css';
import { useColorMode } from 'react-use-color-mode';
const { useToken, darkAlgorithm, defaultAlgorithm } = theme;
import { checkHasAlgorithm } from '@/components/RightContent/ThemeSwitch';
import { TfaCodeComponent } from '@/pages/account/Settings/components/tfa/tfa';
import { checkToken, isExpired } from '../Verify';

const AUTH_REQ_TYPE_ACCOUNT = 'account';
const AUTH_REQ_TYPE_EMAIL_CODE2 = 'email_code2';
const AUTH_REQ_TYPE_SMS_CODE = 'sms_code';
export const AUTH_REQ_TYPE_EMAIL_CODE = 'email_code';
export const AUTH_REQ_TYPE_TFA_CODE = 'tfa_code';

export const AUTH_RES_TYPE_EMAIL_CHECK = 'email_check';
export const AUTH_RES_TYPE_TFA_CHECK = 'tfa_check';

function hashCode(str: string) {
  let hash = 20111116;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash % 16777216;
}

function intToRGB(i: number, a = 1) {
  return (
    'rgba(' + ((i >> 16) & 0xff) + ', ' + ((i >> 8) & 0x7f) + ',' + (i & 0xff) + ',' + a / 255 + ')'
  );
}

function string2RGB(s: string, a = 1) {
  return intToRGB(hashCode(s), a);
}

type LoginResult = {
  status?: string;

  // for email check
  username?: string;
  emailHint?: string;
  secret?: string;
};

const handleLoginDone = async (
  msg: API.LoginResponse,
  failureMsg: string,
  successMsg: string,
  fetchInitialState: { (): Promise<void>; (): any },
  type: string,
) => {
  if (!msg.access_token) {
    message.error(failureMsg);
    return;
  }
  if (!!localStorage.getItem('autoLogin') && type !== AUTH_REQ_TYPE_EMAIL_CODE2) {
    localStorage.setItem(keyAccessToken, msg.access_token);
  } else {
    sessionStorage.setItem(keyAccessToken, msg.access_token);
    localStorage.removeItem(keyAccessToken);
  }
  await fetchInitialState();
  message.success(successMsg);
};

const LoginMessage: React.FC<{
  content: string;
}> = ({ content }) => (
  <Alert
    style={{
      marginBottom: 24,
    }}
    message={content}
    type="error"
    showIcon
  />
);

const oidcIconComponent = (iconSize: number, iconContent?: string) => {
  const style = { width: iconSize, height: iconSize, top: '50%', transform: 'translateY(-50%)' };
  if (iconContent) {
    return <div
      dangerouslySetInnerHTML={{ __html: iconContent }}
      className={styles.oidcIcon}
      style={style}
    />;
  } else {
    // unreachable
    return <div />;
  }
}

const LoginButton = ({
  text,
  iconContent,
  onClick,
  disabled,
  iconSize,
}: {
  text: string;
  iconContent?: string;
  onClick: any;
  disabled: any;
  iconSize: number;
}) => {
  return (
    <Button
      type='primary'
      style={{
        width: '100%',
        background: string2RGB(text, 0x9f),
        border: 'none',
      }}
      icon={oidcIconComponent(iconSize, iconContent)}
      onClick={onClick}
      disabled={disabled}
    >
      <span style={{ marginLeft: 20 }}>{text}</span>
    </Button>
  );
};

const LoginOidc: React.FC<{ options: API.OidcLoginInfo[]; fetchInitialState: { (): Promise<void>; (): any } }> = (
  props,
) => {
  const [loginOp, setLoginOp] = useState<string>('');
  const [loginMsg, setLoginMsg] = useState<string>('');
  const intl = useIntl();
  const antdConfig = useAntdConfig();
  const isDark = checkHasAlgorithm(antdConfig, darkAlgorithm);
  const oidcSettings = getOidcSettings(isDark);

  const plain = (text: string, defaultMessage = '') =>
    intl.formatMessage({
      id: `pages.login.${text}`,
      defaultMessage: defaultMessage || text,
    });

  const handleLoginWith = async (op: string) => {
    setLoginOp(op);
    const browser = Bowser.getParser(window.navigator.userAgent);
    const deviceInfo: API.DeviceInfo = {
      os: browser.getOSName(true),
      type: 'browser',
      name: `${browser.getBrowserName()} - ${browser.getBrowserVersion()}`,
    };
    const callbackUrl = window.location.origin + '/#/welcome';
    try {
      await oidcAuth({ op, callbackUrl, deviceInfo });
    } catch (err) {
      setLoginMsg('failed to request the auth url');
      console.error('Failed to request auth url', err);
      return;
    }
  };

  const LoginItem = ({ data }: { data: API.OidcLoginInfo }) => {
    const iconSize = oidcSettings[data.name]?.icon.size ?? oidcSettings.icon.size;
    const nameRecord = { 'github': 'GitHub', 'gitlab': 'GitLab' };
    const labelOp = nameRecord[data.name.toLocaleLowerCase()] ?? capitalizeFirstLetter(data.name);
    return (
      <>
        <div className={styles.oidcItem}>
          <LoginButton
            text={plain('Continue with ' + labelOp)}
            iconContent={data.icon ?? oidcSettings[data.name]?.icon.content ?? oidcSettings.icon.content}
            onClick={() => handleLoginWith(data.name)}
            key={data.name}
            disabled={loginOp !== '' && loginMsg === ''}
            iconSize={iconSize === undefined ? oidcSettings.icon.size : iconSize}
          />
        </div>
        {loginOp === data.name &&
          ((loginMsg === 'success' && (
            <div className={styles.oidcMsgSuccess}>{t('pages.login.success', true, 'success')}</div>
          )) ||
            (loginMsg !== '' && (
              <div>
                <span>{t('pages.login.failure', true, 'Login failed, please try again!')}</span>
                <span className={styles.oidcMsgFailed}>&nbsp;{loginMsg}</span>
              </div>
            )))}
      </>
    );
  };

  return (
    <>
      {props.options
        .map((item) => (
          <div className={styles.oidcItem} key={item.name}>
            <LoginItem data={item} />
          </div>
        ))}
    </>
  );
};

const AccountLogin: React.FC<{ loginResult: LoginResult }> = (props) => {
  const inputRef = useRef<InputRef>(null);
  const intl = useIntl();

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <>
      {props.loginResult.status && (
        <LoginMessage
          content={intl.formatMessage({
            id: 'pages.login.accountLogin.errorMessage',
          })}
        />
      )}
      <ProFormText
        name="username"
        fieldProps={{
          size: 'large',
          prefix: <UserOutlined className={styles.prefixIcon} />,
          ref: inputRef,
        }}
        placeholder={t('User Name', true) as string}
        rules={[
          {
            required: true,
            whitespace: true,
            message: <FormattedMessage id="pages.login.username.required" />,
          },
        ]}
      />
      <ProFormText.Password
        name="password"
        fieldProps={{
          size: 'large',
          prefix: <LockOutlined className={styles.prefixIcon} />,
        }}
        placeholder={t('Password', true) as string}
        rules={[
          {
            required: true,
            whitespace: true,
            message: <FormattedMessage id="pages.login.password.required" />,
          },
        ]}
      />
    </>
  );
};

type OneTimeLoginProps = {
  loginResult: LoginResult,
  setLoginResult: React.Dispatch<React.SetStateAction<LoginResult>>,
};

const OneTimeLogin: React.FC<OneTimeLoginProps> = (props) => {
  const intl = useIntl();
  return (
    <>
      {props.loginResult.status && <LoginMessage content={t(props.loginResult.status ?? '') as string} />}
      {/*<ProFormText
        fieldProps={{
          size: 'large',
          prefix: <MobileOutlined className={styles.prefixIcon} />,
        }}
        name="mobile"
        placeholder={intl.formatMessage({
          id: 'pages.login.phoneNumber.placeholder',
        })}
        rules={[
          {
            required: true,
            whitespace: true,
            message: <FormattedMessage id="pages.login.phoneNumber.required" />,
          },
          {
            pattern: /^1\d{10}$/,
            message: <FormattedMessage id="pages.login.phoneNumber.invalid" />,
          },
        ]}
      />*/}
      <ProFormText
        fieldProps={{
          size: 'large',
          prefix: <MailOutlined className={styles.prefixIcon} />,
        }}
        name="email"
        placeholder={t('Enter your email address', true) as string}
        rules={[
          {
            required: true,
            whitespace: true,
            message: t('Email is required', true),
          },
          {
            type: 'email',
            message: t('Invalid email format', true),
          },
        ]}
        validateTrigger={['onBlur']}
      />
      <ProFormCaptcha
        fieldProps={{
          size: 'large',
          prefix: <LockOutlined className={styles.prefixIcon} />,
        }}
        captchaProps={{
          size: 'large',
        }}
        placeholder={intl.formatMessage({
          id: 'pages.login.captcha.placeholder',
        })}
        captchaTextRender={(timing, count) => {
          if (timing) {
            return `${count} ${intl.formatMessage({
              id: 'pages.getCaptchaSecondText',
            })}`;
          }
          return intl.formatMessage({
            id: 'pages.login.phoneLogin.getVerificationCode',
          });
        }}
        name="verificationCode"
        rules={[
          {
            required: true,
            whitespace: true,
            message: <FormattedMessage id="pages.login.captcha.required" />,
          },
        ]}
        phoneName="email"
        onGetCaptcha={async (email) => {
          try {
            const { secret } = await getLoginVerificationCode({
              data: { email },
            });
            props.setLoginResult({ secret });
            message.success(t("Verification code sent successfully"));
          } catch (e) {
            if (typeof e == 'string') {
              message.error(e as string);
              return;
            }
            const err = e as ResponseError;
            if (err.response?.status == 401) {
              message.error(t("Failed to get verification code: the user with this email doesn't exist"));
            }
          }
        }}
      />
    </>
  );
};

const EmailCodeLogin: React.FC<{ form: FormInstance<any>, loginResult: LoginResult, onChange: (v: string) => void }> = (props) => {
  const inputRef = useRef<InputRef>(null);
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const codeLen = 6;

  return (
    <>
      {props.loginResult.status && <LoginMessage content={t(props.loginResult.status ?? '') as string} />}
      <ProFormText
        hidden
        name="username"
        initialValue={props.loginResult.username}
        label="User Name"
      />
      <ProFormText
        name="emailHint"
        initialValue={props.loginResult.emailHint}
        label="Email"
        disabled
      />
      <ProFormText
        rules={[
          {
            required: true,
            whitespace: true,
          },
          {
            len: codeLen,
          },
        ]}
        name="verificationCode"
        label="Verification Code"
        fieldProps={{
          ref: inputRef,
        }}
        validateTrigger={['onBlur']}
        onChange={(e: any) => {
          const v = e.target.value;
          props.onChange(v);
          if (v && v.length > codeLen) {
            props.form.validateFields(['verificationCode']);
          }
        }}
      />
    </>
  );
};

const TfaCodeLogin: React.FC<{ form: FormInstance<any>, loginResult: LoginResult, onChange: (v: string) => void }> = (props) => {
  return (
    <>
      {props.loginResult.status && <LoginMessage content={t(props.loginResult.status ?? '') as string} />}
      <ProFormText
        hidden
        name="username"
        initialValue={props.loginResult.username}
        label="User Name"
      />
      <TfaCodeComponent form={props.form} isBackupCodeAcceptable={true} autoFocus={true} onChange={props.onChange} />
    </>
  );
};

const Login: React.FC = () => {
  const [loginResultState, setLoginResultState] = useState<LoginResult>({} as LoginResult);
  const [type, setType] = useState<string>(AUTH_REQ_TYPE_ACCOUNT);
  const [oidcLoginOptions, setOidcLoginOptions] = useState<API.OidcLoginInfo[]>([]);
  const { initialState, setInitialState } = useModel('@@initialState');
  const [logging, setLogging] = useState<boolean>(false);
  const [form] = Form.useForm();
  const intl = useIntl();
  // https://ant.design/docs/react/customize-theme#switch-themes-dynamically
  const { token } = useToken();
  const colorMode = useColorMode();
  const setAntdConfig = useAntdConfigSetter();
  const verifyMode =
    type === AUTH_REQ_TYPE_SMS_CODE ||
    type === AUTH_REQ_TYPE_EMAIL_CODE ||
    type == AUTH_REQ_TYPE_EMAIL_CODE2 ||
    type === AUTH_REQ_TYPE_TFA_CODE;

  const defaultLoginFailureMessage = t(
    'pages.login.failure',
    true,
    'Login failed, please try again!',
  ) as string;
  const defaultLoginSuccessMessage = t('pages.login.success', true, 'Login successful!') as string;

  const actionClassName = useEmotionCss(({ token }) => ({
    display: "flex",
    float: "right",
    height: "48px",
    marginLeft: "auto",
    overflow: "hidden",
    cursor: "pointer",
    padding: "0 12px",
    alignItems: "center",
    borderRadius: token.borderRadius,
    "&:hover": {
      backgroundColor: token.colorBgTextHover,
    },
    ">span": {
      verticalAlign: 'middle',
    },
  }));

  useEffect(() => {
    (async () => {
      try {
        // const res = await getOidcLoginOptions();
        let ops: API.OidcLoginInfo[] = [];
        const res = await getLoginOptions();
        for (const item of res) {
          if (item.startsWith('common-oidc/')) {
            // json decode substring of item to ops
            ops = JSON.parse(item.substring('common-oidc/'.length));
          }
        }
        if (ops.length == 0) {
          for (const item of res) {
            if (item.startsWith('oidc/')) {
              ops.push({ 'name': item.substring('oidc/'.length) } as API.OidcLoginInfo);
            }
          }
        }
        setOidcLoginOptions(ops);
      } catch (error) { }
    })();
  }, []);

  useEffect(() => {
    if (colorMode === 'light') {
      setDarkAlgorithm(false);
    } else if (colorMode === 'dark') {
      setDarkAlgorithm(true);
    }
  }, [colorMode])

  useEffect(() => {
    if (initialState && initialState?.user) {
      const query = queryString.parse(history.location.search);
      const { redirect } = query as { redirect: string };
      // somehow setInitialState doesn't take effect even after await, add a setTimeout as workaround here.
      history.push(redirect || '/');
    } else {
      const query = queryString.parse(history.location.search);
      if (query?.tfa_type == AUTH_RES_TYPE_EMAIL_CHECK) {
        message.success('Need email verification');
        setLoginResultState({
          username: (query?.username ?? '') as string,
          emailHint: (query?.email ?? '') as string,
          secret: (query?.secret ?? '') as string,
        });
        setType(AUTH_REQ_TYPE_EMAIL_CODE);
      } else if (query?.tfa_type == AUTH_RES_TYPE_TFA_CHECK) {
        message.success('Need 2FA verification');
        setLoginResultState({
          username: (query?.username ?? '') as string,
          secret: (query?.secret ?? '') as string,
        });
        setType(AUTH_REQ_TYPE_TFA_CODE);
      } else {
        // do nothing
      }
    }
  }, [initialState]);

  useEffect(() => {
    const query = queryString.parse(history.location.search);
    const { updateEmailToken } = query as { updateEmailToken?: string };
    if (updateEmailToken) {
      const claims = checkToken(updateEmailToken);
      if (!claims) {
        // unreachable
        message.error(t('Invalid token, please check if the url is correct.'));
        return;
      }

      if (isExpired(claims)) {
        message.error(t('Expired token, please resend the email.'));
        return;
      }

      updateUserVerify({ type: 'email', user: claims.user_guid, secret: updateEmailToken }).then(() => {
        message.success(t('Email updated successfully.'));
        if (getToken()) {
          initialState?.fetchInitialState?.().then((info) => {
            setInitialState((s) => ({
              ...s,
              ...info,
            }));
          });
        }
      }
      ).catch((err) => {
        message.error('Failed to update email: ' + err);
      });
    }
  }, [initialState, setInitialState]);

  const setDarkAlgorithm = (dark: boolean) => {
    setAntdConfig({
      theme: {
        algorithm: [
          dark ? darkAlgorithm : defaultAlgorithm,
        ],
      },
    });
  }

  const fetchInitialState = async () => {
    const info = await initialState?.fetchInitialState?.();
    if (info) {
      await setInitialState((s) => ({
        ...s,
        ...info,
      }));
    }
  };

  const handleSubmit = async (values: API.LoginRequest & { tfa_code?: string }) => {
    try {
      values.uuid = getFingerprint();
      const browser = Bowser.getParser(window.navigator.userAgent);
      const deviceInfo: API.DeviceInfo = {
        os: browser.getOSName(true),
        type: 'browser',
        name: `${browser.getBrowserName()} - ${browser.getBrowserVersion()}`,
      };
      values.type = type;
      values.deviceInfo = deviceInfo;
      values.username = values.username?.trim();
      values.password = values.password?.trim();
      values.verificationCode = values.verificationCode?.trim();
      values.tfaCode = values.tfa_code?.trim();
      values.secret = loginResultState.secret;
      const msg = await login(values);

      const isNeedEmailVerification = msg.type === AUTH_RES_TYPE_EMAIL_CHECK &&
        (msg.tfa_type == undefined || msg.tfa_type == AUTH_RES_TYPE_EMAIL_CHECK);
      if (isNeedEmailVerification) {
        message.success(t('Need email verification'));
        setLoginResultState({
          username: values.username,
          emailHint: msg.user.email,
          secret: msg.secret,
        });
        setType(AUTH_REQ_TYPE_EMAIL_CODE);
        return;
      }

      const isNeedTfaVerification = msg.type === AUTH_RES_TYPE_TFA_CHECK ||
        (msg.type === AUTH_RES_TYPE_EMAIL_CHECK && msg.tfa_type === AUTH_RES_TYPE_TFA_CHECK);
      if (isNeedTfaVerification) {
        message.success(t('Need 2FA verification'));
        setLoginResultState({
          username: values.username,
          secret: msg.secret,
        });
        setType(AUTH_REQ_TYPE_TFA_CODE);
        return;
      }

      await handleLoginDone(
        msg,
        defaultLoginFailureMessage,
        defaultLoginSuccessMessage,
        fetchInitialState,
        type,
      );
    } catch (e) {
      if (typeof e == 'string') {
        message.error(e as string);
        return;
      }
      const err = e as ResponseError;
      if (err.response?.status == 401) {
        const error = (err.response?.data?.error?.trim() || '');
        if (error !== '') {
          setLoginResultState({ status: error });
        } else {
          setLoginResultState({ status: 'error' });
        }
      } else {
        message.error(defaultLoginFailureMessage);
      }
    }
  };

  const handleEmailCodeChange = (v: string) => {
    if (v.length == 6) {
      form.submit();
    }
  }

  const handleTfaCodeChange = (v: string) => {
    if (v.length == 6 || v.length == 10) {
      form.submit();
    }
  }

  return fallbackEnUs(
    <div className={styles.container} style={{ backgroundColor: token.colorBgContainer }}>
      <div className={styles.lang} data-lang>
        {SelectLang && <SelectLang className={actionClassName} />}
      </div>
      <div className={styles.content}>
        <LoginForm
          form={form}
          logo={<img alt="logo" src="./logo.svg" />}
          title="RustDesk"
          subTitle={t('app.title', true, appTitle as string)}
          initialValues={{
            // autoLogin is only related to access_token
            // trustTheDevice is whether to add to whitelist, undefined temporarily
            autoLogin: !!localStorage.getItem('autoLogin'),
          }}
          onValuesChange={(values) => {
            // explict changed value
            if (values.autoLogin === true) {
              localStorage.setItem('autoLogin', '1');
            } else if (values.autoLogin === false) {
              localStorage.removeItem('autoLogin');
            }
          }}
          submitter={{
            render: () => {
              return (
                <>
                  <Button loading={logging} type="primary" size="large" block htmlType="submit">
                    {t('pages.login.submit', true, 'Login')}
                  </Button>
                  {!verifyMode && oidcLoginOptions.length != 0 && (
                    <div>
                      <div className={styles.loginOr}>or</div>
                      <LoginOidc
                        key="loginOidc"
                        options={oidcLoginOptions}
                        fetchInitialState={fetchInitialState}
                      />
                    </div>
                  )}
                  {verifyMode && (
                    <Button
                      style={{ marginTop: '20px' }}
                      size="large"
                      block
                      onClick={() => {
                        setLoginResultState({} as LoginResult);
                        setType(AUTH_REQ_TYPE_ACCOUNT);
                      }}
                    >
                      Back
                    </Button>
                  )}
                </>
              );
            },
            submitButtonProps: {
              size: 'large',
              style: {
                width: '100%',
              },
            },
          }}
          onFinish={async (values) => {
            setLogging(true);
            await handleSubmit(values as API.LoginRequest);
            setLogging(false);
          }}
        >
          {!verifyMode && false && (
            <Tabs activeKey={type} onChange={setType}>
              <Tabs.TabPane
                key={AUTH_REQ_TYPE_ACCOUNT}
                tab={t('pages.login.accountLogin.tab', true, 'Account Login')}
              />
              {false && (
                <Tabs.TabPane
                  key={AUTH_REQ_TYPE_EMAIL_CODE2}
                  tab={intl.formatMessage({
                    id: 'pages.login.phoneLogin.tab',
                  })}
                />
              )}
            </Tabs>
          )}

          {type === AUTH_REQ_TYPE_ACCOUNT && <AccountLogin loginResult={loginResultState} />}
          {type === AUTH_REQ_TYPE_EMAIL_CODE2 && <OneTimeLogin loginResult={loginResultState} setLoginResult={setLoginResultState} />}
          {type === AUTH_REQ_TYPE_EMAIL_CODE && <EmailCodeLogin form={form} loginResult={loginResultState} onChange={handleEmailCodeChange} />}
          {type === AUTH_REQ_TYPE_TFA_CODE && <TfaCodeLogin form={form} loginResult={loginResultState} onChange={handleTfaCodeChange} />}

          <div
            style={{
              marginBottom: 24,
            }}
          >
            {type !== AUTH_REQ_TYPE_EMAIL_CODE2 && (
              <ProFormCheckbox
                noStyle
                name="autoLogin"
                fieldProps={{ disabled: type === AUTH_REQ_TYPE_EMAIL_CODE2 }}
              >
                {t('pages.login.rememberMe', true, 'Remember me')}
              </ProFormCheckbox>
            )}
            {!verifyMode && (
              <a
                style={{
                  float: 'right',
                }}
                onClick={() => {
                  setType(AUTH_REQ_TYPE_EMAIL_CODE2);
                }}
              >
                {t('pages.login.forgotPassword', true, 'Forgot Password ?')}
              </a>
            )}
          </div>
        </LoginForm>
      </div>
      <Footer />
    </div>,
  );
};

export default Login;
